import $ from "jquery";
import { barbaInit } from "./modules/barba";
import { burgerInit } from "./components";
import { isMobile } from "./modules/functions";

$(document).ready(init);

function init() {
  burgerInit();
  barbaInit();
  if (!isMobile()) {
    document.getElementsByTagName("body")[0].classList.add("no-touch");
  }

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}
