import { disableScroll, enableScroll } from "../modules/functions";

export class BuildingSlider {
  currentSlide = 0;
  sliderLength;
  scrollDisabled = true;
  $slider;
  $sliderImages;
  $sliderStickers;
  lastAnimation;
  transition = 1000;

  constructor() {
    this.initSlider();
  }

  initSlider() {
    this.$slider = $(".building-slider");
    this.$sliderImages = this.$slider.find(".building-slider-images");
    this.$sliderStickers = this.$slider.find(".building-slider-stickers");
    this.sliderLength = this.$slider.find(".building-slider-change").length - 1;

    this.$sliderImages
      .find("img")
      .eq(this.currentSlide)
      .addClass("active");
    this.$sliderStickers
      .find(".sticker")
      .eq(this.currentSlide)
      .addClass("active");
    this.$slider
      .find(".building-slider-change")
      .eq(this.currentSlide)
      .addClass("active");

    window.scrollTo(0, 0);

    disableScroll();
    this.initScrollSlider();
    this.initTouchSlider();
  }

  initScrollSlider() {
    this.$slider.on("wheel", e => {
      if (
        e.originalEvent.deltaY > 0 &&
        window.pageYOffset === 0 &&
        !this.isMoving()
      ) {
        this.lastAnimation = new Date().getTime();
        this.nextSlide();
      }

      if (
        e.originalEvent.deltaY < 0 &&
        window.pageYOffset === 0 &&
        !this.isMoving()
      ) {
        this.lastAnimation = new Date().getTime();
        this.prevSlide();
      }

      if (this.scrollDisabled) e.preventDefault();
    });
  }

  initTouchSlider() {
    let touchStart;
    this.$slider.bind("touchstart", e => {
      touchStart = e.originalEvent.changedTouches[0].clientY;
      if (this.scrollDisabled) e.preventDefault();
    });

    this.$slider.on("touchend", e => {
      const touchEnd = e.originalEvent.changedTouches[0].clientY;
      if (
        touchStart > touchEnd &&
        window.pageYOffset === 0 &&
        !this.isMoving()
      ) {
        this.lastAnimation = new Date().getTime();
        this.nextSlide();
      }

      if (
        touchStart < touchEnd &&
        window.pageYOffset === 0 &&
        !this.isMoving()
      ) {
        this.lastAnimation = new Date().getTime();
        this.prevSlide();
      }

      if (this.scrollDisabled) e.preventDefault();
    });
  }

  prevSlide() {
    if (this.currentSlide > 0) {
      this.currentSlide--;
      this.changeSlide();
    }
    this.checkDisabledScroll();
  }

  nextSlide() {
    if (this.currentSlide < this.sliderLength) {
      this.currentSlide++;
      this.changeSlide();

      if (this.currentSlide >= this.sliderLength) {
        setTimeout(() => {
          this.checkEnableScroll();
        }, this.transition);
      } else {
        this.checkDisabledScroll();
      }
    } else {
      this.checkEnableScroll();
    }
  }

  changeSlide() {
    this.$sliderImages.find(".active").removeClass("active");
    this.$sliderStickers.find(".active").removeClass("active");
    this.$slider.find(".active").removeClass("active");

    this.$sliderImages
      .find("img")
      .eq(this.currentSlide)
      .addClass("active");
    this.$sliderStickers
      .find(".sticker")
      .eq(this.currentSlide)
      .addClass("active");
    this.$slider
      .find(".building-slider-change")
      .eq(this.currentSlide)
      .addClass("active");
  }

  checkDisabledScroll() {
    if (!this.scrollDisabled) {
      this.scrollDisabled = true;
      disableScroll();
    }
  }

  checkEnableScroll() {
    if (this.scrollDisabled) {
      this.scrollDisabled = false;
      enableScroll();
    }
  }

  isMoving() {
    const timeNow = new Date().getTime();
    return timeNow - this.lastAnimation <= this.transition;
  }
}
