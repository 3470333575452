import { WHITE_MAP_STYLES } from "../modules/map-style";
import { createPopupClass } from "../modules/map-popup-class";

export class Neighborhood {
  $preview = $(".neighborhood-preview");
  $previewNav = $(".neighborhood-preview-navigation");
  $neighborhoodMap = $("#neighborhood-map");
  $neighborhoodMapPopups = $(".neighborhood-map-popups");
  $mapNav = $(".neighborhood-map-navigation");
  $mapNavType = $(".neighborhood-map-navigation-type");
  $mapClose = $(".neighborhood-map-close");
  $sidebar = $(".neighborhood-map-sidebar");
  posMarkers = {};
  infoWindows = {};
  clickMarkers = {};
  mapMarkers = {};
  icons;
  map;

  constructor() {
    this.init();
  }

  init() {
    this.initPreviewNav();
    this.initIcons();
    this.initMap();
    this.initMarkers();
    this.initPointNav();
    this.initCloseMap();
  }

  initIcons() {
    this.icons = {
      "lunch-and-coffee": "/images/neighborhood/point-lunch-and-coffee",
      "fitness-and-health": "/images/neighborhood/point-fitness-and-health",
      "dinner-and-drinks": "/images/neighborhood/point-dinner-and-drinks",
      "arts-and-entertainment":
        "/images/neighborhood/point-arts-and-entertainment",
      transportation: "/images/neighborhood/point-transportation"
    };
  }

  initPreviewNav() {
    this.$previewNav.find("li").on("mouseenter", e => {
      const $t = $(e.currentTarget);
      const $target = $(`#${$t.data("type")}-image`);
      $target.removeClass("hide");
      $target.addClass("show");
    });
    this.$previewNav.find("li").on("mouseleave", e => {
      const $t = $(e.currentTarget);
      const $target = $(`#${$t.data("type")}-image`);
      $target.removeClass("show");
      $target.addClass("hide");
    });

    this.$previewNav.find("li").on("click", e => {
      const $t = $(e.currentTarget);
      const type = $t.data("type");
      this.$preview.fadeOut(300);
      this.$sidebar.find(".active").removeClass("active");
      this.$mapNav.find(`li[data-type="nav-${type}"]`).addClass("active");
      this.$sidebar.find(`.${type}-icon`).addClass("active");
      this.showMarkers();
      this.showTypeMarker(type);
    });

    $(".neighborhood-preview-mobile-close").on("click", e => {
      e.preventDefault();
      this.$preview.fadeOut(300);
    });

    $(".neighborhood-map-mobile-category").on("click", e => {
      e.preventDefault();
      this.$preview.fadeIn(300);
    });
  }

  initMap() {
    if (!this.$neighborhoodMap.length) return;
    const mapContainer = document.getElementById("neighborhood-map");

    this.map = new google.maps.Map(mapContainer, {
      zoom: parseInt(mapContainer.dataset.zoom),
      center: {
        lat: parseFloat(mapContainer.dataset.lat),
        lng: parseFloat(mapContainer.dataset.lng)
      },
      disableDefaultUI: true,
      styles: WHITE_MAP_STYLES
    });

    const transitLayer = new google.maps.TransitLayer();
    transitLayer.setMap(this.map);

    const building = new google.maps.Marker({
      position: new google.maps.LatLng(
        parseFloat(mapContainer.dataset.lat),
        parseFloat(mapContainer.dataset.lng)
      ),
      map: this.map,
      icon: "/images/neighborhood/point-logo.png",
      anchor: new google.maps.Point(30, 20),
      scaledSize: new google.maps.Size(60, 40)
    });
  }

  initMarkers() {
    const Popup = createPopupClass();
    $.getJSON("/neighborhood.json", data => {
      this.mapMarkers = data["data"];

      const counts = {
        "lunch-and-coffee": 0,
        "fitness-and-health": 0,
        "dinner-and-drinks": 0,
        "arts-and-entertainment": 0,
        transportation: 0
      };

      for (let marker in this.mapMarkers) {
        const currentMarker = this.mapMarkers[marker];
        counts[currentMarker.type]++;

        this.$mapNav
          .find('[data-type="nav-' + currentMarker.type + '"] ul')
          .append(
            '<li class="neighborhood-map-navigation-point" id="' +
              currentMarker.id +
              '">' +
              counts[currentMarker.type] +
              ". " +
              currentMarker.content +
              "</li>"
          );

        const image = {
          url: this.icons[currentMarker.type] + ".png",
          size: new google.maps.Size(24, 29),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 29)
        };

        this.posMarkers[marker] = new google.maps.Marker({
          position: new google.maps.LatLng(
            currentMarker.lat,
            currentMarker.lng
          ),
          map: this.map,
          icon: image,
          visible: currentMarker.visible,
          label: counts[currentMarker.type].toString(),
          anchorPoint: new google.maps.Point(20, 0),
          scaledSize: new google.maps.Size(24, 29),
          labelAnchor: new google.maps.Point(15, 29)
        });

        const div = $(
          `
                    <div id="neighborhood-map-info-popup-${marker}" class="popup-${currentMarker.type}">
                    <div class="content">
                    <div class="text">${currentMarker.content}</div>` +
            (currentMarker.url
              ? `<a class='link' href=${currentMarker.url} target='_blank' rel='nofollow'>View&nbsp;Details</a>`
              : "") +
            "</div>"
        );

        this.$neighborhoodMapPopups.append(div);

        this.infoWindows[marker] = new Popup(
          new google.maps.LatLng(currentMarker.lat, currentMarker.lng),
          document.getElementById("neighborhood-map-info-popup-" + marker)
        );

        this.infoWindows[marker].setMap(this.map);

        this.clickMarkers[marker] = (marker => {
          return e => {
            const pos = this.posMarkers[marker].getPosition();
            this.map.panTo(pos);

            this.showMarkers();
            this.posMarkers[marker].setOpacity(0);

            this.$mapNavType.find(".active").removeClass("active");
            $("#" + currentMarker.id).addClass("active");

            $("[id^=neighborhood-map-info-popup-]")
              .parent()
              .hide();
            $("#neighborhood-map-info-popup-" + marker)
              .parent()
              .show();
          };
        })(marker);

        this.posMarkers[marker].addListener("click", this.clickMarkers[marker]);
      }
    });
  }

  initPointNav() {
    $(document).on("click", ".neighborhood-map-navigation-point", e => {
      let index = undefined;
      const $t = $(e.currentTarget);

      for (let marker in this.mapMarkers) {
        if (this.mapMarkers[marker].id === parseInt($t.attr("id"))) {
          index = marker;
          this.map.panTo(this.posMarkers[marker].getPosition());

          this.showMarkers();
          this.posMarkers[marker].setOpacity(0);
        }
      }
      $(".neighborhood-map-navigation-point").removeClass("active");
      $t.addClass("active");
      $("[id^=neighborhood-map-info-popup-]")
        .parent()
        .hide();
      $("#neighborhood-map-info-popup-" + index)
        .parent()
        .show();
    });
  }

  initCloseMap() {
    this.$mapClose.on("click", () => {
      this.$preview
        .css("display", "flex")
        .hide()
        .fadeIn(300);
    });
  }

  showMarkers() {
    for (let marker in this.mapMarkers) {
      this.posMarkers[marker].setOpacity(1);
    }
  }

  showTypeMarker(type) {
    for (let marker in this.mapMarkers) {
      this.posMarkers[marker].setOpacity(1);

      if (type === this.mapMarkers[marker].type) {
        this.posMarkers[marker].setVisible(true);
        $("#neighborhood-map-info-popup-" + marker)
          .parent()
          .hide();
      } else {
        this.posMarkers[marker].setVisible(false);

        $("#neighborhood-map-info-popup-" + marker)
          .parent()
          .hide();
      }
    }
  }
}
