import barba from "@barba/core";
import $ from "jquery";
import {
  BuildingSlider,
  FloorList,
  gallerySlider,
  galleryTab,
  homeSlider,
  popupInit,
  sectionSlider,
  tenants,
  unitInit,
  UnitList
} from "../components";
import { Neighborhood } from "../modules/neighborhood";

export function barbaInit() {
  let init = true;
  barba.hooks.enter(() => {
    window.scrollTo(0, 0);
  });
  barba.init({
    views: [
      {
        namespace: "home",
        beforeLeave() {},
        afterEnter(data) {
          $("body").addClass("overflow-hidden");
          homeSlider();
          init = false;

          $(".home-slider").on("touchend", () => {
            let video = document.querySelector("video");
            if (video) {
              video.play();
            }
          });
        }
      },
      {
        namespace: "building",
        afterEnter(data) {
          if (!init) $("body").removeClass("overflow-hidden");
          const buildingSlider = new BuildingSlider();
          sectionSlider();
          tenants();
          popupInit();
          init = false;
        }
      },
      {
        namespace: "gallery",
        afterEnter(data) {
          if (!init) $("body").removeClass("overflow-hidden");
          init = false;
          gallerySlider();
          galleryTab();
        }
      },
      {
        namespace: "contact",
        afterEnter(data) {
          if (!init) $("body").removeClass("overflow-hidden");
          init = false;
        }
      },
      {
        namespace: "neighborhood",
        afterEnter(data) {
          if (!init) $("body").removeClass("overflow-hidden");
          init = false;
          const neighborhood = new Neighborhood();
        }
      },
      {
        namespace: "community",
        afterEnter(data) {
          if (!init) $("body").removeClass("overflow-hidden");
          init = false;
        }
      },
      {
        namespace: "floor-list",
        afterEnter(data) {
          const floorList = new FloorList();
          $("body").removeClass("overflow-hidden");
        }
      },
      {
        namespace: "unit-list",
        afterEnter(data) {
          const unitList = new UnitList();
          $("body").removeClass("overflow-hidden");
        }
      },
      {
        namespace: "unit-item",
        afterEnter(data) {
          unitInit();
          $("body").removeClass("overflow-hidden");
        }
      }
    ],
    transitions: [
      {
        name: "to-all",
        enter() {},
        leave(data) {},
        after(data) {}
      }
    ]
  });
}
