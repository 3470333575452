export function burgerInit() {
    const $b = $('.button-burger');
    const $h = $('html');
    const showClass = 'show-navigation';
    const hideClass = 'hide-navigation';
    const timeout = 500;

    function hideNavigation() {
        $h.removeClass(showClass).addClass(hideClass);
        setTimeout(() => {
            $h.removeClass(hideClass);
        }, timeout);
    }

    $b.on('click', () => {

        if($h.is('.' + showClass)) {
            hideNavigation();
        } else {
            $h.addClass(showClass);
        }
    });


    $('.navigation').find('a').on('click', ()=> {
        hideNavigation();
    });
}
