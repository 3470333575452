import Swiper, { EffectFade, Navigation, Pagination } from "swiper";
import SwiperCore from "swiper";

export function sectionSlider() {
  const sliders = document.querySelectorAll(".slider-section-slider");
  SwiperCore.use([Pagination, EffectFade, Navigation]);
  for (const slider of sliders) {
    new Swiper(slider, {
      slidesPerView: 1,
      loop: true,
      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
      breakpoints: {
        0: {
          pagination: {
            el: slider.querySelector(".swiper-pagination"),
            clickable: true
          },
          navigation: false
        },
        768: {
          pagination: false,
          navigation: {
            nextEl: slider.querySelector(".slider-section-next"),
            prevEl: slider.querySelector(".slider-section-prev")
          }
        }
      }
    });
  }
}
