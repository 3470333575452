export function addClassSvgEl($el, className) {
  const itemClasses = $el.attr("class");

  $el.attr("class", itemClasses + " " + className);
}

export function removeClassSvgEl($el, className) {
  const itemClasses = $el.attr("class");

  const replace = new RegExp(" " + className, "g");

  $el.attr("class", itemClasses.replace(replace, ""));
}

export function addSlashToPath(path) {
  let newPath = path;

  if (newPath.slice(-1) !== "/") {
    newPath += "/";
  }

  return newPath;
}

export function isMobile() {
  return $(window).width() < 768;
}

export function isTable() {
  return $(window).width() < 992;
}

export function disableScroll() {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

  window.onscroll = () => {
    window.scrollTo(scrollLeft, scrollTop);
  };
}

export function enableScroll() {
  window.onscroll = () => {};
}
