export function unitInit() {
  const $interiorPreview = $(".interior-preview");
  $(".view-interior").on("click", () => {
    $interiorPreview.fadeIn(300);
  });

  $(".interior-preview-close").on("click", () => {
    $interiorPreview.fadeOut(300);
  });

  $(".show-mobile-unit-plan").on("click", () => {
    $(".unit-plan-mobile").addClass("show");
  });

  $(".unit-plan-mobile-close").on("click", () => {
    $(".unit-plan-mobile").removeClass("show");
  });
}
