import barba from "@barba/core";
import {
  isTable,
  isMobile,
  addClassSvgEl,
  removeClassSvgEl
} from "../modules/functions";

export class FloorList {
  $floorList = $(".floor-list");
  $activeInfo;
  $floorMobileButton = $(".floor-list-mobile-button");
  $floorMobileInfoFloor = $(".floorplan-page-mobile-info-floor");

  constructor() {
    this.init();
  }

  init() {
    if (!isMobile()) this.floorHover();
    this.floorClick();
  }

  floorHover() {
    this.$floorList.find(".floor-list-item").on("mouseenter", e => {
      const $t = $(e.currentTarget);
      const floor = $t.data("floor");
      $t.parent().addClass("opacity");
      $(`#floor-image-${floor}`).addClass("show");
      this.$activeInfo = $(`#floor-info-${floor}`);
      this.$activeInfo.addClass("active");
    });

    this.$floorList.find(".floor-list-item").on("mouseleave", e => {
      $(e.currentTarget)
        .parent()
        .removeClass("opacity");
      this.$activeInfo.removeClass("active");
      $(".floor-list-image").removeClass("show opacity");
    });
  }

  floorClick() {
    this.$floorList.find(".floor-list-item").on("click", e => {
      const $t = $(e.currentTarget);

      if (isTable()) {
        this.$floorMobileButton
          .attr("href", $t.data("link"))
          .addClass("active");
        this.$floorMobileInfoFloor.text(`Level ${$t.data("floor")}`);

        this.$floorList
          .find(".floor-list-item")
          .each((index, item) => removeClassSvgEl($(item), "active"));
        addClassSvgEl($t, "active");

        $(document).bind("click.outside-floor", e => {
          if (!$(e.target).closest(".floor-list-item").length) {
            $(document).unbind("click.outside-floor");
            this.$floorMobileButton.removeClass("active");
            this.$floorMobileInfoFloor.text("");

            this.$floorList
              .find(".floor-list-item")
              .each((index, item) => removeClassSvgEl($(item), "active"));
          }
        });
      } else {
        barba.go($t.data("link"));
      }
    });
  }
}
