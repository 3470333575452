export function galleryTab() {
    const $galleryNavigationA = $('.gallery-tab-navigation a');

    $galleryNavigationA.on('click', (e) => {
        e.preventDefault();
        const $t = $(e.currentTarget);
        if(!$t.is('.active')) {
            $('.gallery-tab-navigation .active').removeClass('active');
            $('.gallery-tab.active').removeClass('active');
            $t.addClass('active');
            $($t.attr('href')).addClass('active');
        }
    });
}
