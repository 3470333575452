const animationDuration = 600;

export function popupInit() {
    $(document).on('click', '.popup-open', (e) => {
        e.preventDefault();
        let target;
        const href = $(e.currentTarget).attr('href');
        if (!href) {
            target = $(e.currentTarget).data('popup');
        } else {
            target = href;
        }

        if (!target) return;

        const $popup = $(target);

        openPopup($popup);
    });

    $(document).on('click', '.popup-close', (e) => {
        e.preventDefault();
        closePopup($(e.currentTarget).parents('.popup'));
    });
}

/**
 *
 * @param {jQuery | HTMLElement} $popup
 */
export function openPopup($popup) {
    $('body').addClass('overflow-hidden');
    $popup.addClass('popup-show');
}

/**
 *
 * @param {jQuery | HTMLElement} $parent
 */
export function closePopup($parent) {
    $('body').removeClass('overflow-hidden');
    $parent.addClass('popup-hide');

    setTimeout(() => {
        $parent.removeClass('popup-show').removeClass('popup-hide');
    }, animationDuration)
}
