import Swiper from "swiper/bundle";

function geSlideDataIndex(swipe) {
  let activeIndex = swipe.activeIndex;
  let slidesLen = swipe.slides.length;
  if (swipe.params.loop) {
    switch (swipe.activeIndex) {
      case 0:
        activeIndex = slidesLen - 3;
        break;
      case slidesLen - 1:
        activeIndex = 0;
        break;
      default:
        --activeIndex;
    }
  }
  return activeIndex;
}

export function gallerySlider() {
  $(".gallery-slider").each((i, el) => {
    $(el)
      .parent()
      .find(".gallery-slider-count-total")
      .text($(el).find(".swiper-slide").length);

    let swiper = new Swiper(el, {
      slidesPerView: 1,
      loop: true,
      effect: "fade",
      preloadImages: false,
      lazy: true,
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        nextEl: $(el)
          .find(".gallery-slider-next")
          .get(0),
        prevEl: $(el)
          .find(".gallery-slider-prev")
          .get(0)
      },
      on: {
        slideChange: function(swipe) {
          $(el)
            .parent()
            .find(".gallery-slider-count-current")
            .text(geSlideDataIndex(swipe) + 1);
        }
      }
    });
  });

  setTimeout(() => {
    $(".gallery-tab").removeClass("active");
    $(".gallery-tab")
      .eq(0)
      .addClass("active");
  }, 500);
}
