import Swiper from 'swiper/bundle';

export function homeSlider() {
    const slider = new Swiper('.home-slider', {
        slidesPerView: 1,
        autoplay: {
            delay: 5000,
        },
        loop: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
    });
}
