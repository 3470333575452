import barba from "@barba/core";

export class UnitList {
  $unitList = $(".unit-list");
  $activeInfo;
  $floor;

  constructor() {
    this.init();
  }

  init() {
    this.unitClick();
    this.unitHover();
    this.$floor = $(".floor-info.active");
  }

  unitHover() {
    this.$unitList.find(".unit-list-item").on("mouseenter", e => {
      const $t = $(e.currentTarget);
      this.$floor.removeClass("active");
      this.$activeInfo = $(`#unit-info-${$t.data("unit")}`);
      this.$activeInfo.addClass("active");
    });

    this.$unitList.find(".unit-list-item").on("mouseleave", () => {
      this.$activeInfo.removeClass("active");
      this.$floor.addClass("active");
    });
  }

  unitClick() {
    this.$unitList.find(".unit-list-item").on("click", e => {
      const $t = $(e.currentTarget);
      barba.go($t.data("link"));
    });
  }
}
