export function tenants() {
    const $tenantsListItem = $('.tenants-list .tenants-list-item');
    $tenantsListItem.on('mouseenter', (e) => {
        const $t = $(e.currentTarget);
        const $target = $(`#${$t.data('image')}`);
        $target.removeClass('hide');
        $target.addClass('show');
    });
    $tenantsListItem.on('mouseleave', (e) => {
        const $t = $(e.currentTarget);
        const $target = $(`#${$t.data('image')}`);
        $target.removeClass('show');
        $target.addClass('hide');
    });
}
