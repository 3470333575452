export function initPreview() {
  if (sessionStorage.getItem("preview")) {
    $(".preview").hide();
  } else {
    $("body").addClass("overflow-hidden");

    sessionStorage.setItem("preview", "shown");

    $(document).bind("wheel.preview", () => {
      $(".preview").fadeOut(300);
      if ($(".home-page").length === 0)
        $("body").removeClass("overflow-hidden");
      $(document).unbind("wheel.preview");
    });
  }
}
